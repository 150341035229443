import {ScreensEnum} from "@/Screens.enum";
import {ScreenDefinitions} from "@/Screens.types";
import {markRaw} from "vue";
import BioplasticsScreenIntro from "@/components/screens/BioplasticsScreenIntro.vue";
import ScreenQuestionButtonsBP from "@/components/screens/ScreenQuestionButtonsBP.vue";
import ScreenQuestionMultipleOptionsBP from "@/components/screens/ScreenQuestionMultipleOptionsBP.vue";
import ScreenQuestionsTrueFalse from "@/components/screens/ScreenQuestionsTrueFalse.vue";
import ScreenFactsBP from "@/components/screens/ScreenFactsBP.vue";
import ScreenQuestionSlider from "@/components/screens/ScreenQuestionSlider.vue";
import ScreenBannerBP from "@/components/screens/ScreenBannerBP.vue";
import ScreenQuestionSortList from "@/components/screens/ScreenQuestionSortList.vue";
import ScreenQuestionOptionsBP from "@/components/screens/ScreenQuestionOptionsBP.vue";
import ScreenBanner2ColsBP from "@/components/screens/ScreenBanner2ColsBP.vue";
import ScreenFinalVideoBP from "@/components/screens/ScreenFinalVideoBP.vue";

export const getScreensBioPlastics = ($t, state): ScreenDefinitions => {
    return {
        [ScreensEnum.BIOPLASTICS_INTRO]: {
            component: markRaw(BioplasticsScreenIntro),
            screenId: ScreensEnum.BIOPLASTICS_INTRO,
            title: 'BIOPLASTICS_INTRO.title',
            answerKey: 'BIOPLASTICS_INTRO',
            // bgImage: "url('img/scirt/rjuha.png')",
            // image1: "img/scirt/dekle1.png",
            textPart1: 'BIOPLASTICS_INTRO.par1',
            textPart2: 'BIOPLASTICS_INTRO.par2',
            textPart3: 'BIOPLASTICS_INTRO.par3',
            textPart4: 'BIOPLASTICS_INTRO.disclaimer',
            screenNext: ScreensEnum.DoYouUseDisposable,
            classes: {
                wrapperClasses: 'bpe-bg-blue',
                buttonClasses: 'green-button',
            }
        },

        [ScreensEnum.DoYouUseDisposable]: {
            component: markRaw(ScreenQuestionButtonsBP),
            screenId: ScreensEnum.DoYouUseDisposable,
            screenNext: ScreensEnum.HowOfterYouUseDisposable,
            title: 'DoYouUseDisposable.question',
            question: '',
            answerKey: 'DoYouUseDisposable',
            options: {
                'idont': 'DoYouUseDisposable.idont',
                'singleuse': 'DoYouUseDisposable.singleuse',
                'multipleuse': 'DoYouUseDisposable.multipleuse',
                'both': 'DoYouUseDisposable.both',
            },
            optionsFlow: {
                'idont': ScreensEnum.BestDescribeYourUse,
            },
            bgImage: "/img/bioplastics/tableset.jpg",
            classes: {
                wrapperClasses: 'bg-gray',
                buttonClasses: 'blue-button',
            }
        },

        [ScreensEnum.HowOfterYouUseDisposable]: {
            component: markRaw(ScreenQuestionButtonsBP),
            screenId: ScreensEnum.HowOfterYouUseDisposable,
            screenNext: ScreensEnum.BestDescribeYourUse,
            title: 'HowOfterYouUseDisposable.question',
            question: '',
            themeDark: true,
            answerKey: 'HowOfterYouUseDisposable',
            options: {
                'daily': 'HowOfterYouUseDisposable.daily',
                'weekly': 'HowOfterYouUseDisposable.weekly',
                'monthly': 'HowOfterYouUseDisposable.monthly',
                'lessmonthly': 'HowOfterYouUseDisposable.lessmonthly',
                'notatall': 'HowOfterYouUseDisposable.notatall',
                'unsure': 'HowOfterYouUseDisposable.unsure',
            },
            bgImage: "/img/bioplastics/box.png",
            bgStyles: 'padding: 0;',
            classes: {
                wrapperClasses: 'bpe-bg-blue',
                buttonClasses: 'green-button',
            }
        },

        [ScreensEnum.BestDescribeYourUse]: {
            component: markRaw(ScreenQuestionMultipleOptionsBP),
            screenId: ScreensEnum.BestDescribeYourUse,
            screenNext: ScreensEnum.TestYouKnowledge,
            title: 'BestDescribeYourUse.question',
            question: '',
            answerKey: 'BestDescribeYourUse',
            options: {
                'dontthink': 'BestDescribeYourUse.dontthink',
                'itspractical': 'BestDescribeYourUse.itspractical',
                'avoidifinconvenient': 'BestDescribeYourUse.avoidifinconvenient',
                'activelyavoid': 'BestDescribeYourUse.activelyavoid',
                'sometimesbuy': 'BestDescribeYourUse.sometimesbuy',
                'usewithmealorevent': 'BestDescribeYourUse.usewithmealorevent',
            },
            bgImage: "/img/bioplastics/handset.png",
            bgStyles: 'padding: 0;',
            classes: {
                buttonClasses: 'blue-button',
            }
        },

        [ScreensEnum.TestYouKnowledge]: {
            component: markRaw(ScreenQuestionsTrueFalse),
            screenId: ScreensEnum.TestYouKnowledge,
            screenNext: ScreensEnum.HierarchyFacts,
            themeDark: true,
            title: 'TestYouKnowledge.intro',
            answerKey: 'TestYouKnowledge',
            questions: {
                'eurecycles': 'TestYouKnowledge.eurecycles',
                'biobasedmaterials': 'TestYouKnowledge.biobasedmaterials',
                'halfissingleuse': 'TestYouKnowledge.halfissingleuse',
                'singleusebanned': 'TestYouKnowledge.singleusebanned',
            },

            answers: {
                'eurecycles': false,
                'biobasedmaterials': true,
                'halfissingleuse': true,
                'singleusebanned': true,
            },
            classes: {
                wrapperClasses: 'bpe-bg-blue',
                buttonClasses: 'green-button',
                questionText: 'text-project2'
            },
            bgImage: "/img/bioplastics/forks.jpg",
        },

        [ScreensEnum.HierarchyFacts]: {
            component: markRaw(ScreenFactsBP),
            screenId: ScreensEnum.HierarchyFacts,
            screenNext: ScreensEnum.SustainabilityPriority,
            answerKey: 'HierarchyFacts',
            title: 'HierarchyFacts.title',
            bgImage: "/img/bioplastics/phyramid.png",
        },

        [ScreensEnum.SustainabilityPriority]: {
            component: markRaw(ScreenQuestionSlider),
            screenId: ScreensEnum.SustainabilityPriority,
            screenNext: ScreensEnum.ExcellentNews,
            title: '',
            question: 'SustainabilityPriority.question',
            bgImage: "url(/img/bioplastics/cup.png)",
            bgStyles: 'background-size: contain;',
            answerKey: [{
                key: 'SustainabilityPriorityPrice',
                text: 'SustainabilityPriority.price'
            }, {
                key: 'SustainabilityPriorityConvenience',
                text: 'SustainabilityPriority.convenience'
            }],
            answerMin: 1,
            answerMax: 5,
            answerStep: 1,
            answerUnit: '',
            sliderSubtext: '(1 is least, 5 is most)',
            classes: {
                questionClass: 'font-bold text-4xl text-project',
                sliderTextClass: 'uppercase',
                sliderClass: 'slider-bioplastics'
            },
            sliderAlternate: false,
        },
        [ScreensEnum.ExcellentNews]: {
            component: markRaw(ScreenBannerBP),
            screenId: ScreensEnum.ExcellentNews,
            screenNext: ScreensEnum.OccasionsToSwitch,
            themeDark: true,
            answerKey: 'ExcellentNews',
            title: 'ExcellentNews.title',
            textPart1: 'ExcellentNews.subtitle',
            textPart2: 'ExcellentNews.text1',
            textPart3: 'ExcellentNews.text2',
            image1: "/img/bioplastics/people.png",
            classes: {
                wrapperClasses: 'bpe-bg-blue',
            }
        },
        [ScreensEnum.OccasionsToSwitch]: {
            component: markRaw(ScreenQuestionSlider),
            screenId: ScreensEnum.SustainabilityPriority,
            screenNext: ScreensEnum.ShockingFact,
            title: '',
            question: 'OccasionsToSwitch.question',
            bgImage: "url(/img/bioplastics/forks3.png)",
            bgStyles: "background-size: contain;",
            answerKey: [
                {
                    key: 'OccasionsToSwitch.picnic',
                    text: 'OccasionsToSwitch.picnic'
                },
                {
                    key: 'OccasionsToSwitch.homeparty',
                    text: 'OccasionsToSwitch.homeparty'
                }, {
                    key: 'OccasionsToSwitch.restaurant',
                    text: 'OccasionsToSwitch.restaurant'
                },
                {
                    key: 'OccasionsToSwitch.takeaway',
                    text: 'OccasionsToSwitch.takeaway'
                },
                {
                    key: 'OccasionsToSwitch.fooddelivery',
                    text: 'OccasionsToSwitch.fooddelivery'
                },
                {
                    key: 'OccasionsToSwitch.prepareforthego',
                    text: 'OccasionsToSwitch.prepareforthego'
                },
            ],
            answerMin: 1,
            answerMax: 5,
            answerStep: 1,
            answerUnit: '',
            sliderSubtext: '(1 is least, 5 is most)',
            classes: {
                questionClass: 'font-bold text-4xl text-project',
                sliderTextClass: 'uppercase',
                sliderClass: 'slider-bioplastics'
            },
            sliderAlternate: false,
        },
        [ScreensEnum.ShockingFact]: {
            component: markRaw(ScreenBannerBP),
            screenId: ScreensEnum.ShockingFact,
            screenNext: ScreensEnum.WhichInformationIsNeeded,
            themeDark: true,
            answerKey: 'ShockingFact',
            title: 'ShockingFact.title',
            textPart1: '',
            textPart2: 'ShockingFact.text1',
            textPart3: 'ShockingFact.text2',
            image1: "/img/bioplastics/beach.png",
            classes: {
                wrapperClasses: 'bpe-bg-blue',
            }
        },

        [ScreensEnum.WhichInformationIsNeeded]: {
            component: markRaw(ScreenQuestionSortList),
            screenId: ScreensEnum.WhichInformationIsNeeded,
            screenNext: ScreensEnum.SustainableSymbols,
            title: '',
            question: 'WhichInformationIsNeeded.question',
            textPart3: 'WhichInformationIsNeeded.text1',
            options: {
                'retailerinfo': 'WhichInformationIsNeeded.retailerinfo',
                'packaginginfo': 'WhichInformationIsNeeded.packaginginfo',
                'symbol': 'WhichInformationIsNeeded.symbol',
                'ban': 'WhichInformationIsNeeded.ban',
            },
            answerKey: 'WhichInformationIsNeeded',
            bgImage: "url('img/bioplastics/plasticcups.png')",
            bgStyles: 'background-size: 75%; background-position: bottom;',
            themeDark: true,
            classes: {
                wrapperClasses: 'bpe-bg-blue',
                questionClass: 'font-bold text-4xl',
                sortButtonClass: 'green-button',
                nextButtonClass: 'green-button',
            }
        },


        [ScreensEnum.SustainableSymbols]: {
            component: markRaw(ScreenQuestionOptionsBP),
            screenId: ScreensEnum.SustainableSymbols,
            screenNext: ScreensEnum.WhereDoYouLive,
            title: '',
            question: 'SustainableSymbols.question',
            textPart3: 'SustainableSymbols.text1',
            textPart4: 'SustainableSymbols.righttext',
            options: {
                'notassuring': 'SustainableSymbols.notassuring',
                'slightly': 'SustainableSymbols.slightly',
                'moderately': 'SustainableSymbols.moderately',
                'assuring': 'SustainableSymbols.assuring',
                'veryassuring': 'SustainableSymbols.veryassuring',
            },
            answerKey: 'SustainableSymbols',
            bgImage: "/img/bioplastics/glasses.png",
            image1: "/img/bioplastics/greenstarslogo.png",
            bgStyles: 'background-size: 75%; background-position: bottom;',
            themeDark: false,
            classes: {
                questionClass: 'font-bold text-project text-4xl',
                sortButtonClass: 'green-button',
                nextButtonClass: 'green-button',
                optionClass: 'text-project text-2xl font-normal',
            }
        },

        [ScreensEnum.WhereDoYouLive]: {
            component: markRaw(ScreenQuestionButtonsBP),
            screenId: ScreensEnum.WhereDoYouLive,
            screenNext: ScreensEnum.WhichAgeGroup,
            title: 'WhereDoYouLive.question',
            question: '',
            answerKey: 'WhereDoYouLive',
            options: {
                'city': 'WhereDoYouLive.city',
                'suburbs': 'WhereDoYouLive.suburbs',
                'countryside': 'WhereDoYouLive.countryside',
            },
            bgImage: "/img/bioplastics/village.jpg",
            classes: {
                wrapperClasses: 'bg-gray',
                buttonClasses: 'blue-button',
            }
        },

        [ScreensEnum.WhichAgeGroup]: {
            component: markRaw(ScreenQuestionButtonsBP),
            screenId: ScreensEnum.WhichAgeGroup,
            screenNext: ScreensEnum.KeyTakeaways,
            title: 'WhichAgeGroup.question',
            question: '',
            answerKey: 'WhichAgeGroup',
            options: {
                '1-20': '1 - 20',
                '21-40': '21 - 40',
                '41-60': '41 - 60',
                '61+': '61+',

            },
            bgImage: "/img/bioplastics/people2.jpg.jpg",
            classes: {
                wrapperClasses: 'bg-gray',
                buttonClasses: 'blue-button',
            }
        },
        [ScreensEnum.KeyTakeaways]: {
            component: markRaw(ScreenBanner2ColsBP),
            screenId: ScreensEnum.KeyTakeaways,
            screenNext: ScreensEnum.FinalScreen,
            title: 'KeyTakeaways.title',
            answerKey: 'KeyTakeaways',
            themeDark: true,
            classes: {
                wrapperClasses: 'bpe-bg-blue',
            }
        },

        [ScreensEnum.FinalScreen]: {
            component: markRaw(ScreenFinalVideoBP),
            screenId: ScreensEnum.FinalScreen,
            screenNext: ScreensEnum.BIOPLASTICS_INTRO,
            answerKey: 'BIOPLASTICS_FINAL',
            title: 'BIOPLASTICS_FINAL.title',
            textPart1: 'BIOPLASTICS_FINAL.text1',
            textPart2: 'BIOPLASTICS_FINAL.text2',
            textPart3: 'BIOPLASTICS_FINAL.text3',
            textPart4: 'Check our <a href="https://www.prospex-institute.org/interactive-experience" target="_blank">PI e-Lab</a> where you can find more tools like this.',
            bgImage: "url('/img/bioplastics/colorset.png')",
            bgStyles: 'background-size: contain; background-position: bottom;',
            classes: {
                wrapperClasses: 'text-project text-2xl',
            }
        },

    } as ScreenDefinitions
}
