
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {useState} from "@/hooks/state";
import {ScreenSliders} from "@/Screens.types";
import Slider from "@/components/elements/Slider.vue";

const state = useState();
export default defineComponent({
  components: {
    Slider,
    ScreenWrapper,
  },
  props: {
    msg: String,
    goTo: Function,
    config: Object as () => ScreenSliders,
  },

  setup() {
    return {
      state
    }
  },
  data() {
    return {
      screen: this.config.screenId,
    }
  },

  mounted() {
  },

  computed: {
    sliderKeys(): { key: string, text?: string }[] {
      return Array.isArray(this.config.answerKey) ? this.config.answerKey : [{key: this.config.answerKey}]
    }
  },

  methods: {
    next() {
      this.goTo(this.config.screenNext)
    }, back() {
      this.goTo(-1)
    },

    saveAll() {
      this.sliderKeys.forEach((sliderDef) => {
        if (!state.answers.value?.[sliderDef.key]) {
          // Save all not answered sliders with default value
          this.answer(null, sliderDef.key, {[sliderDef.key]: `${this.$t(this.config.question)} - ${this.$t(sliderDef.text)}`})
        }
      })
    },

    answer(answer: string | number, key: string, texts: object) {
      if (!answer) {
        // middle value
        if (this.config.answerOptions?.length > 0) {
          answer = this.config.answerOptions[Math.ceil(this.config.answerOptions.length / 2)].id;
        } else {
          // median step between min and max, with step
          answer = Math.ceil((this.config.answerMin + this.config.answerMax) / 2 / this.config.answerStep) * this.config.answerStep;
        }
      }

      if (this.config.answerOptions?.length > 0) {
        texts[answer] = this.$t(this.config.answerOptions.find(option => option.id === answer)?.name);
      }

      state.setAnswer(key || this.config.answerKey, answer, {
        ...texts
      });
      // this.next();
    }
  }
});
