import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row justify-center sm:justify-start w-full back-next-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionButton = _resolveComponent("ActionButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ActionButton, {
      text: "buttons.back",
      "color-class": _ctx.colorClass,
      "button-class": _ctx.buttonClass,
      onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.click(-1); _ctx.goTo(-1);})
    }, null, 8, ["color-class", "button-class"]),
    _createVNode(_component_ActionButton, {
      class: "ml-4",
      text: "buttons.next",
      "color-class": _ctx.colorClass,
      "button-class": _ctx.buttonClass,
      onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.click(1); _ctx.goTo(_ctx.nextScreen);})
    }, null, 8, ["color-class", "button-class"])
  ]))
}