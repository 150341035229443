import {createI18n} from 'vue-i18n'
import enMessages from './locales/en'
import deMessages from './locales/de'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
    return {
        en: enMessages,
        de: deMessages
    }
}

const queryParams = new URLSearchParams(window.location.search)

const defaultLocale = queryParams.get('locale') || process.env.VUE_APP_I18N_LOCALE || 'en';
console.log(queryParams, defaultLocale)

export default createI18n({
    locale: defaultLocale,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages()
})
