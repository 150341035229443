
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {useState} from "@/hooks/state";
import {ScreenTrueFalse} from "@/Screens.types";

const state = useState();
export default defineComponent({
  components: {
    ScreenWrapper
  },
  props: {
    msg: String,
    goTo: Function,
    config: Object as () => ScreenTrueFalse,
  },

  setup() {
    return {
      state
    }
  },
  data() {
    return {
      screen: this.config.screenId,
      showNicknameModal: false,
      showQuestion: false,
    }
  },

  mounted() {
    // setTimeout(() => {
    // this.$root.setupAndPlayIfOn(this.FILES.audio_welcome)
    // }, 1000)
  },

  computed: {
    selected() {
      return this.state.answers.value?.[this.config.answerKey] || {};
    },

    falseButtonData() {
      return Object.fromEntries(Object.keys(this.config.answers).map(key => {
        const answer = this.checkAnswer(key, false);

        if (answer === undefined) {
          return [key, undefined]
        }

        return [key, {
          icon: answer ? 'img/bioplastics/icon-ok.png' : 'img/bioplastics/icon-nok.png',
          class: answer ? 'jump' : 'shake'
        }]
      }));
    },

    trueButtonData() {
      return Object.fromEntries(Object.keys(this.config.answers).map(key => {
        const answer = this.checkAnswer(key, true);

        if (answer === undefined) {
          return [key, undefined]
        }

        return [key, {
          icon: answer ? 'img/bioplastics/icon-ok.png' : 'img/bioplastics/icon-nok.png',
          class: answer ? 'jump' : 'shake'
        }]
      }));
    },
    hasAllAnswers() {
      return Object.keys(this.config.answers).every(key => this.selected?.[key] !== undefined);
    }
  },

  methods: {
    next() {
      this.goTo(this.config.screenNext)
    },
    back() {
      this.goTo(-1)
    },
    start() {
      // Open modal for nicname
      this.showNicknameModal = true;
    },

    checkAnswer(key, expectedValue) {
      if (this.selected?.[key] === undefined) {
        return undefined;
      }

      if (this.selected?.[key] !== expectedValue) {
        return undefined;
      }

      return this.selected?.[key] === this.config.answers[key];
    },

    answer(key: string | number, value) {
      const state = this.state.answers.value?.[this.config.answerKey] || {};
      state[key] = value;
      const texts = Object.keys(this.config.questions).reduce((acc, key) => {
        acc[key] = this.$t(this.config.questions[key]);
        return acc;
      }, {});
      this.state.setAnswer(this.config.answerKey, state, {
        [this.config.answerKey]: this.$t(this.config.textPart1 ?? this.config.title),
        ...texts
      });

      // If all answers are given, go to next screen. Check that all keys were answered. State might conatin some additional keys

      if (this.hasAllAnswers) {
        setTimeout(() => {
          this.next();
        }, 2000)
      }
    }
  }
});
