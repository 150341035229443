export default {
    "message": "hello i18n !!",
    "screen": {
        "welcome": "Willkommen!",
        "lets-start": "Lassen Sie uns beginnen...",
        "devices": "Versuchen Sie, zu raten",
        "video": "Was sind SE?",
        "map": "Wem gehören SE?",
        "alternative-supply": "Unsere Alternativen",
        "get-magnets": "Das Magnetlabor",
        "magnets-structure": "Legierungsherstellung",
        "magnets-process": "Letzter Schritt",
        "close-loop": "Den Kreis schliessen!",
        "challenges": "Drei Herausforderungen",
        "feedback": "Ihr Feedback!",
        "action-time": "Zeit für Action",
        "guess": "Können Sie erraten, welche dieser Produkte Legierungen oder Magnete aus Seltenen Erden enthalten?"
    },
    "almost-end": {
        "title": "Wir sind fast am Ende unseres Bürgerlabors angelangt!",
        "insufficient": "Unzureichend",
        "barely": "Kaum ausreichend",
        "okay": "Gut",
        "satisfying": "Zufriedenstellend",
        "great": "Großartig",
        "no": "Ich glaube nicht, dass es meine Entscheidungen beeinflussen wird",
        "prioritize": "Ich werde wahrscheinlich der Nachhaltigkeit Vorrang vor anderen Faktoren wie dem Preis geben",
        "will-pay": "Ich werde mehr auf Nachhaltigkeit achten, aber andere Faktoren sind mir wichtiger, wie z. B. der Preis",
        "subtitle": "Wir sind fast am Ende unseres Bürgerlabors angelangt! Wir haben nur noch ein paar Fragen bevor wir uns verabschieden.",
        "question1": "Wie würden Sie Ihr Wissen über Seltene Erden und damit verbundene Herausforderungen bewerten, nun da Sie das Ende dieses Bürgerlabors erreicht haben?",
        "question2": "Sie wissen, dass Seltene Erden in vielen Produkten enthalten sind, die Sie täglich benutzen, und dass es bei ihrer Herstellung unterschiedliche Nachhaltigkeitsstandards gibt. Wenn Sie Zugang zu Informationen über die Nachhaltigkeit dieser Produkte hätten (z. B. durch ein Zertifikat), würde dies Ihre zukünftigen Kaufentscheidungen beeinflussen?"
    },
    "eu-footer": "Die beiden Projekte werden durch das Forschungs und Innovationsprogramm Horizont 2020 der Europäischen Union unter den Finanzhilfevereinbarungen Nr. 776559 (SecREEts) und Nr. 821114 (SUSMAGPRO) gefördert.",
    "buttons": {
        "submit": "Einreichen",
        "yes": "JA",
        "no": "NEIN"
    },
    "read less": "Weniger lesen",
    "read more": "Mehr lesen",
    "map": {
        "button-instructions": "Klicken Sie auf die Karte, um eine Region auszuwählen",
        "card-text": "China stellt 98% der Lieferungen von Seltenen Erden an die EU. Das bedeutet, dass alle Produkte, die wir zuvor identifiziert haben, davon abhängig sind, dass China eines der Materialien liefert.",
        "map-notice": "Die regionale Einteilung auf dieser Karte basiert auf",
        "map-notice-link": "diesem Artikel",
        "title": "Obwohl Seltene Erden an vielen Orten der Welt zu finden sind, dominiert derzeit ein Land den Abbau von Seltenen Erden und die Produktion von Seltenerdmagneten. Können Sie erraten, welches Land das ist?"
    },
    "That's correct": "Das stimmt",
    "That's incorrect": "Das ist falsch",
    "intro": {
        "title": "DiscoveREEs",
        "subtitle": "Ein virtuelles Bürgerlabor von <br> SecREEts und SUSMAGPRO",
        "title2": "Erweitern Sie Ihr WisSEn!",
        "par1": "Willkommen in diesem Online-Bürgerlabor, einer interaktiven Plattform zur Erforschung von Problemen und ihren Lösungen und zum Meinungsaustausch. Diese Plattform wird von SecREEts und SUSMAGPRO betrieben, zwei von der EU finanzierten Forschungsprojekten. Ziel ist es, europäische Bürgerinnen und Bürger einzubeziehen, über wissenschaftliche Innovationen zu informieren, die sich auf unser tägliches Leben auswirken, und Feedback einzuholen. Sie müssen kein Experte sein, um mitzumachen, das Bürgerlabor ist für jeden offen.",
        "par2": "In diesem Bürgerlabor erforschen wir Seltene Erden (SE), die insbesondere für die Herstellung von Legierungen und Permanentmagneten verwendet werden, sowie die Frage, warum sie für unsere Gesellschaft und Wirtschaft so wichtig sind und welche Herausforderungen sich dadurch ergeben. Wir werfen einen Blick darauf, wie man diese wertvollen Rohmaterialien zurückgewinnen und Recycling und Wiederverwendung fördern kann.",
        "par3": "Wenn Sie bereit sind, das Bürgerlabor zu starten, dann klicken Sie auf den Link.",
        "button": "Lassen Sie uns über Magnete sprechen"
    },
    "step2": {
        "title": "Kennen Sie sich mit Seltenen Erden aus?",
        "okcard": "Ein Teil der Seltenen Erden wird für die <b>Herstellung von Legierungen und Permanentmagneten benötigt</b>, die viele der Produkte, <b>die wir täglich benutzen</b>, effizienter machen.",
        "nokcard": "Tatsächlich sind sie nicht vielen Menschen bekannt. Ein Teil der Seltenen Erden wird für die <b>Herstellung von Legierungen und Permanentmagneten benötigt</b>, die viele der Produkte, <b>die wir täglich benutzen</b>, effizienter machen.",
        "continue": "Klicken Sie auf den Pfeil, <br> um fortzufahren"
    },
    "Great!": "Großartig!",
    "Check answers": "Antworten prüfen",
    "Click or tap on the images above": "Klicken oder tippen Sie auf die Bilder oben",
    "devices": {
        "card": {
            "par1": "Alle Produkte, die Sie ausgewählt haben, enthalten Legierungen oder Magnete aus Seltenen Erden! Unsere moderne Gesellschaft und Wirtschaft hängen stark von ihnen ab. Schauen wir uns einmal genauer an, was Seltene Erden sind.",
            "par2": "Wissen Sie, warum sie als selten bezeichnet werden?"
        }
    },
    "alternativeSupply": {
        "title": "Eine alternative Versorgung aufbauen",
        "text1": "Mehrere Länder sind dabei, eine alternative Versorgung mit Seltenen Erden aufzubauen. In Europa wollen wir sicherstellen, dass der Abbau und die Weiterverarbeitung von Seltenen Erden, insbesondere für die Herstellung von Legierungen und Permanentmagneten:",
        "bullet1": "besser für die Umwelt sind,",
        "bullet2": "Gesundheits-, Sicherheits- und Menschenrechtsstandards erfüllen",
        "bullet3": "eine nachhaltige Produktion und Nutzung ermöglichen",
        "bullet4": "und schließlich, dass sie auch die von den Vereinten Nationen definierten Ziele für nachhaltige Entwicklung unterstützen.\n",
        "text2": "Um dies zu erreichen, müssen wir die Art und Weise ändern, in der Seltene Erden gewonnen, verwendet und entsorgt werden, und hier beginnt der Spaß. Dank SecREEts und dem innovativen industriellen Verfahren von SUSMAGPRO ist es möglich, einen <b>100% nachhaltigen Magneten herzustellen</b>. Werfen Sie einen Blick darauf und haben Sie Spaß beim Experimentieren mit den verschiedenen Möglichkeiten, Ihren Magneten herzustellen!"
    },
    "getMagnets": {
        "title": "Woher möchten Sie Ihren Magneten gewinnen?",
        "title2": "Das ist eine kluge Entscheidung!",
        "title3": "Wussten Sie schon?",
        "title4": "Eine kluge Entscheidung!",
        "fertiliser": "Aus der Düngemittelproduktion!",
        "recycle": "Durch Recycling!",
        "text1": "Seltene Erden sind von Natur aus in einigen phosphathaltigen Gesteinen, besser bekannt als Apatit, enthalten, die zur Herstellung von Düngemitteln verwendet werden. Offensichtlich brauchen Pflanzen die Seltenen Erden in Düngemitteln nicht, um zu wachsen! Daher entwickelt SecREEts ein Verfahren zur Gewinnung von Seltenen Erden aus dem Phosphatgestein, das bei der Düngemittelherstellung verwendet wird. Diese Technologie trägt dazu bei, zusätzlichen Bergbau zu vermeiden, indem der Wert bereits bestehender Produktionsprozesse maximiert wird.",
        "text2": "Je nach Definition gibt es bis zu 17 verschiedene Seltene Erden, die alle unterschiedliche Anwendungen haben! SecREEts entwickelt ein umweltfreundliches Verfahren, um Seltene Erden voneinander zu trennen und in hochreine Oxide zu verwandeln.",
        "text3": "Diese mehrfarbigen Pulver können dann je nachdem, welches Element in welcher Menge benötigt wird, ausgewählt werden.",
        "text4": "Wussten Sie, dass viele ausrangierte Produkte erhebliche Mengen an Seltenen Erden enthalten? Anstatt sich auf den Abbau und die Verarbeitung von Neuware zu verlassen, wird in SUSMAGPRO das Recycling erforscht. Im Rahmen des Projekts wird eine automatische Sortieranlage entwickelt, mit der die Teile, die Magnete mit Seltenen Erden enthalten (\"SE-Magnete\"), aus Computerfestplatten, Lautsprechern, Game Controllern und anderen Gegenständen, die nicht mehr in Gebrauch sind, effizient identifiziert und extrahiert werden können.",
        "text5": "Darüber hinaus werden spezifische Informationen über die Zusammensetzung und die Position von Seltenerdmagneten, die in Abfallprodukten gefunden werden, in einer Datenbank gesammelt und Sortier- und Recyclingunternehmen zur Verfügung gestellt. Dies erleichtert die Demontage von Teilen und die Extraktion von Magneten und macht so das Recycling attraktiver. Die gesammelten Daten fließen auch in die Produktentwicklung ein, so dass die Produkte so gestaltet werden, dass die Magnete am Ende ihrer Lebensdauer leichter aus den Produkten entfernt und recycelt werden können.",
        "text6": "Natürlich nützen all diese Informationen nichts, wenn ausgediente Produkte in den hintersten Schränken und dunklen Kellerecken versteckt werden. Bitte suchen Sie also Ihre gebrauchten Elektrogeräte heraus und bringen Sie sie zur nächsten offiziellen Sammelstelle!",
        "text7": "In SUSMAGPRO werden die Komponenten, die Seltenerdmagnete enthalten und am Ende ihrer Lebensdauer aus den Produkten extrahiert wurden, mit Hilfe von Wasserstoff in einem geschlossenen Reaktor durch das sogenannte Hydrogen Processing of Magnetic Scrap (HPMS) in Pulver zerlegt. Mit dieser patentierten Technologie kann man oft nicht nur den Magneten, sondern auch das ihn umgebende Material in ein Pulver überführen, was das Recycling erleichtert und den Zeitaufwand für die vollständige Extraktion der Magnete verringert.",
        "text8": "Die gemischten Pulver werden dann getrennt und gereinigt, sodass hochwertiges Seltene Erden-Pulver entsteht, das direkt zur Herstellung von Legierungen oder Magneten verwendet werden kann. Im Vergleich zu herkömmlichen Recyclingverfahren für Altkomponenten werden Zeit, Ressourcen, Energie und Geld gespart.",
        "text9": "Hydrogen Processing of Magnetic Scrap (Pforzheim University for SUSMAGPRO)",
        "checkVideo": "Schauen Sie sich das Video an &#187;"
    },
    "magnetStructure": {
        "text1": "Jetzt sind unsere Seltenen Erden einsatzbereit, großartig! Aber Seltene Erden allein können nicht viel ausrichten: In den meisten Anwendungen werden sie mit anderen, gebräuchlicheren Metallen, wie z. B. Eisen, legiert. In SecREEts und SUSMAGPRO können Legierungen mit der richtigen Menge der richtigen Seltenen Erden für die spezifische Endanwendung hergestellt werden.",
        "text2": "Die für starke Permanentmagnete am häufigsten verwendete Legierung besteht aus Neodym (Nd), Eisen (Fe) und Bor (B).",
        "text3": "Um diese Neodym-Eisen-Bor-Legierung zu erhalten, wird zunächst Neodymoxid in einem innovativen Elektrolyseverfahren geschmolzen. Das so entstandene Neodym wird dann in einem Bandgießofen bei sehr hoher Temperatur mit Eisen und Bor legiert, bevor es zu Flocken verarbeitet wird. In der letzten Stufe wird die Legierung im Wasserstoff-Versprödungs-Ofen zu Pulver verarbeitet. Und dann sind unsere Permanentmagnete schon fast fertig!"
    },
    "magnetsProcess": {
        "title": "Letzter Schritt: <br> Umwandlung von Neodym-Eisen-Bor-Pulver in Magnete!",
        "text1": "Je nach Anforderungen und Anwendung gibt es verschiedene Möglichkeiten. Ein Magnet in einer Windkraftanlage hat natürlich nicht die gleiche Form oder Größe wie ein Magnet in Ihrem Smartphone-Lautsprecher! <br> Das gängigste Herstellungsverfahren, das <b>\"Sintern\"</b>, wird sowohl in SUSMAGPRO als auch in SecREEts verwendet. In SUSMAGPRO werden zusätzlich noch weitere Verfahren angewandt.",
        "text2": "Welches Produktionsverfahren auch immer gewählt wird, es beinhaltet normalerweise eine Vorbehandlung des Legierungspulvers (z. B. <b>Mahlen</b>, um die Pulverpartikel kleiner zu machen), so dass es leicht geformt werden kann und beginnt, wie ein Magnet auszusehen. Hier kann das Pulver mit Hilfe eines Magnetfeldes in die gleiche Richtung ausgerichtet werden, was die Stärke des Magneten erhöht. Es folgt eine Verarbeitung bei hoher und stabiler Temperatur, wobei darauf geachtet wird, dass der restliche Sauerstoff aus dem Magneten entfernt wird, da dieser die Leistung des Magneten beeinträchtigen könnte. Dies ist auch der Moment, in dem wir den Magneten mit Beschichtung vor Korrosion schützen. In einem letzten Schritt wird das Teil magnetisiert.",
        "text3": "Und fertig, frisch aus der Presse, haben wir einen Permanentmagnet, der zu 100 % in Europa hergestellt wurde und in einer Vielzahl von Endprodukten eingesetzt werden kann!"
    },
    "question1": {
        "title": "Jetzt geht Ihr Kühlschrank oder Ihr Smartphone kaputt und kann nicht mehr repariert werden: schade! Aber das ist nicht das Ende für die darin verwendeten Permanentmagnete: Wenn Sie Ihre Produkte verantwortungsvoll entsorgen, können sie wieder in den SUSMAGPRO-Kreislauf eintreten.",
        "btnBack": "Noch einen Magneten herstellen!",
        "btnNext": "Genug Magnete für heute"
    },
    "challenges": {
        "title": "Welche wichtigen Herausforderungen gehen wir hier an?",
        "text1": "Die derzeitige Versorgung mit Seltenen Erden und Magneten ist mit einer Vielzahl von Herausforderungen verbunden. <br> Klicken Sie auf den Text, um herauszufinden, wie SUSMAGPRO und SecREEts diese angehen:",
        "text2": "Abbau und Verarbeitung sind mit Umweltschäden sowie Gesundheits- und Sicherheitsproblemen verbunden",
        "text3": "Die Recyclingquote für Seltene Erden, insbesondere für die Herstellung von Legierungen und Permanentmagneten, ist zu niedrig",
        "text4": "Hohe Nachfrage und Abhängigkeit bei Legierungen und Magneten aus Seltenen Erden",
        "readMore": "Mehr lesen",
        "solutions": "SecREEts & SUSMAGPRO-Lösungen",
        "card1": {
            "title": "Abbau und Verarbeitung sind mit Umweltschäden sowie Gesundheits- und Sicherheitsproblemen verbunden",
            "text1": "Bei den derzeitigen Abbau- und Verarbeitungsmethoden fallen giftige und radioaktive Abfälle als Nebenprodukt an. Wenn es keine Vorschriften gibt oder diese nicht eingehalten werden, können Säuren, Schwermetalle und radioaktive Elemente das Grundwasser verschmutzen. Weitere Probleme sind Schädigung der Vegetation und Bodenerosion. Außerdem mangelt es an Transparenz in den Wertschöpfungsketten, und es ist kaum oder gar nicht bekannt, welche Sozial-, Sicherheits- und Umweltstandards angewandt werden.",
            "bullet1": "In SecREEts werden Seltene Erden aus europäischen Apatitquellen gewonnen, die in der Düngemittelproduktion verwendet werden.",
            "bullet2": "In SUSMAGPRO werden Seltene Erden aus ausgedienten Magneten in verschiedenen europäischen Abfallprodukten zurückgewonnen.",
            "text2": "In beiden Projekten werden sekundäre Quellen genutzt:",
            "text3": "Diese Seltenen Erden können dann zur Herstellung von Legierungen, Permanentmagneten oder anderen Produkten verwendet werden. Auf diese Weise unterstützen SecREEts und SUSMAGPRO mehr Kreislaufwirtschaft und Nachhaltigkeit in den europäischen Wertschöpfungsketten. In beiden Projekten werden zudem soziale und ökologische Auswirkungen der Projektaktivitäten bewertet und Gesundheit und Sicherheit in den Mittelpunkt der Wertschöpfungsketten gestellt."
        },
        "card2": {
            "title": "Die Recyclingquote für Seltene Erden, insbesondere für die Herstellung von Legierungen und Permanentmagneten, ist zu niedrig",
            "text1": "Derzeit ist die Recyclingquote für Seltene Erden sehr niedrig. Um eine echte Kreislaufwirtschaft zu etablieren und sicherzustellen, dass wertvolle Ressourcen nicht verschwendet werden, ist eine Erhöhung dieser Quote unerlässlich.",
            "text2": "In beiden Projekten werden Lösungen für die Wiederverwendung und Umwandlung von Abfällen und Nebenströmen, die Seltene Erden enthalten, entwickelt. Auch wenn es sich bei einigen dieser Lösungen um Innovationen handelt, können sie von der Industrie sofort eingesetzt werden. Durch eine breitere Anwendung können sie noch effizienter werden."
        },
        "card3": {
            "title": "Hohe Nachfrage und Abhängigkeit bei Legierungen und Magneten aus Seltenen Erden",
            "text1": "Mit zunehmenden Digitalisierung und der grünen Energiewende ist die Nachfrage nach Seltenen Erden in Europa hoch und wird in Zukunft voraussichtlich noch weiter steigen. Weniger als 10 % der derzeitigen Nachfrage nach Neodym-Eisen-Bor wird durch EU-Produktion gedeckt, und der Markt wird von einem einzigen Land beherrscht. Dies führt zu einer hohen Preisvolatilität und einem hohen Versorgungsrisiko.",
            "text2": "Beide Projekte nutzen alternative Versorgungsquellen und schaffen Produktionsanlagen in Europa, was zum Aufbau einer widerstandsfähigeren und sichereren Versorgungskette für Seltene Erden sowie zur Schaffung von Arbeitsplätzen in Europa beiträgt."
        }
    },
    "nowWhat": {
        "title": "Was können Sie jetzt tun?",
        "section1": {
            "title": "Informieren Sie sich!",
            "text1": "Hier sind einige nützliche Links zu weiteren Informationen über Seltene Erden: <br>",
            "text2": "Woher kommt Ihr Smartphone? Entdecken Sie es mit",
            "text3": "Besuchen Sie auch die Websites von",
            "text4": ", um weitere Informationen über die Projekte zu erhalten!",
            "text5": "Wir sind auch in den sozialen Medien vertreten:<br>",
            "text6": "SUSMAGPRO und SecREEts unterstützen mehrere der Ziele für Nachhaltige Entwicklung der Vereinten Nationen. Erfahren Sie mehr über die",
            "text7": "und wie auch Sie sie unterstützen können!",
            "text8": "dieser interaktiven Plattform."
        },
        "section2": {
            "title": "Unterstützen Sie Recycling!",
            "text1": "Entsorgen Sie Abfälle ordnungsgemäß und tragen Sie so zum Urban Mining (dem Prozess der Rückgewinnung von Rohstoffen von der Bevölkerung durch Recycling von Abfällen) bei",
            "text2": "Finden Sie mit diesem ",
            "text3": " heraus, wie viele wertvolle Materialien sich in Ihrem Haushalt befinden.",
            "text4": "Stellen Sie Fragen vor dem Kauf:<br>",
            "text5": "Woher kommen die Produkte?<br>",
            "text6": "Wie werden sie hergestellt?<br>",
            "text7": "Was passiert am Ende ihrer Lebensdauer?<br>"
        },
        "section3": {
            "title": "Geben Sie die Botschaft weiter!",
            "text1": "Sensibilisieren Sie Ihre Mitmenschen: Hier finden Sie nützliches, leicht verständliches Material, das Sie weitergeben können."
        },
        "section4": {
            "title": "Stellen Sie uns Ihre Fragen!",
            "text1": "Wenn Sie sich mit bestimmten Fragen oder Punkten an uns wenden möchten, senden Sie uns bitte eine E-Mail an"
        }
    },
    "and": "und"
}
