
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {useState} from "@/hooks/state";
import {ScreenConfigOptions} from "@/Screens.types";

const state = useState();
export default defineComponent({
  components: {
    ScreenWrapper
  },
  props: {
    msg: String,
    goTo: Function,
    config: Object as () => ScreenConfigOptions,
  },

  setup() {
    return {
      state
    }
  },
  data() {
    return {
      screen: this.config.screenId,
      showNicknameModal: false,
      showQuestion: false,
    }
  },

  mounted() {
    // setTimeout(() => {
    // this.$root.setupAndPlayIfOn(this.FILES.audio_welcome)
    // }, 1000)
  },

  methods: {
    next() {
      this.goTo(this.config.screenNext)
    },
    back() {
      this.goTo(-1)
    },
    start() {
      // Open modal for nicname
      this.showNicknameModal = true;
    },
  }
});
