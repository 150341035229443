
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {useState} from "@/hooks/state";
import {ScreenConfigOptions} from "@/Screens.types";

const state = useState();
export default defineComponent({
  components: {
    ScreenWrapper
  },
  props: {
    msg: String,
    goTo: Function,
    config: Object as () => ScreenConfigOptions,
  },

  setup() {
    return {
      state
    }
  },
  data() {
    return {
      screen: this.config.screenId,
      showNicknameModal: false,
      showQuestion: false,
      selected: {},
    }
  },

  mounted() {
    this.selected = this.state.answers.value?.[this.config.answerKey] || {};
  },

  methods: {
    next() {
      this.goTo(this.config.screenNext)
    },
    back() {
      this.goTo(-1)
    },
    start() {
      // Open modal for nicname
      this.showNicknameModal = true;
    },
    answer(answer: string | number) {
      this.selected[answer] = !this.selected[answer];
      const texts = Object.keys(this.config.options).reduce((acc, key) => {
        acc[key] = this.$t(this.config.options[key]);
        return acc;
      }, {});
      state.setAnswer(this.config.answerKey, this.selected, {
        [this.config.answerKey]: this.$t(this.config.textPart1 ?? this.config.title),
        ...texts
      });
    },
    saveAndGoTo(goTo) {
      const texts = Object.keys(this.config.options).reduce((acc, key) => {
        acc[key] = this.$t(this.config.options[key]);
        return acc;
      }, {});
      state.setAnswer(this.config.answerKey, this.selected, {
        [this.config.answerKey]: this.$t(this.config.textPart1 ?? this.config.title),
        ...texts
      });
      this.goTo(goTo);
    }
  }
});
